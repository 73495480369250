.App{
  width: 100vw;
  height: 100vh;
}

.tagline{
  position: relative;
  /* left: 50%; */
  top: 50%; 
  text-align: center;
}

a{
  text-decoration: none;
}